import "./set-public-path";

export { track } from "./analytics/analytics";
export { getPublicPath } from "./utilities/get-public-path";

export function getPrototypeSurvey() {
  return import(
    /* webpackChunkName: "prototype-survey" */ "./prototypes/prototype-survey.component"
  );
}
