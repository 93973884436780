import { of } from "rxjs";
import { first, timeoutWith } from "rxjs/operators";
import { v4 as uuid } from "uuid";
import { AuthService, CanopyError } from "../types/global";

export async function track(
  product: string,
  feature: string,
  event: string,
  _data: Record<string, any> = {}
) {
  if (!product) throw new Error("Analytics events must require a product");
  if (!feature) throw new Error("Analytics events must require a feature");
  if (!event) throw new Error("Analytics events must require a event name");
  if (typeof _data !== "object")
    throw new Error("Analytics event data must be an object");

  const data = Object.assign(_data, {
    user_agent: navigator.userAgent,
  });

  const stack = new Error().stack;
  const { user, tenant } = await getLoggedInUserAndTenant();
  const nowDate = getNowDate();
  const userId = user && user.id;
  const tenantId = tenant && tenant.id;
  const { appIsMobile, nativeMobileSource } = window;

  let client_app_name = "canopy_web";
  if (appIsMobile) {
    client_app_name = nativeMobileSource || "canopy_mobile_web";
  }

  try {
    const resp = await fetch(`/api/analytics`, {
      method: "POST",
      body: JSON.stringify({
        header: {
          client_app_name,
          originator: "frontend",
          correlation_id: uuid(),
          parameters: {
            products: product,
            features: feature,
            events: event,
          },
        },
        body: {
          data: data,
          event_date: nowDate.getTime(),
          // The userId from the given event may not be defined.
          // Also, the moment we are processing the event the user still may not be defined.
          // If neither, send up 0 for the tenant_id and "0" user_id.
          tenant_id: tenantId || 0,
          user_id: userId || "0",
        },
      }),
    });
    if (!resp?.ok) throw new Error(`Cannot save event: ${resp.status}`);
  } catch (e) {
    recordException(e, stack);
    console.error(e);
  }
}

async function getLoggedInUserAndTenant() {
  const auth: AuthService = await System.import("cp-client-auth!sofe");

  const user = await auth.default
    .getLoggedInUserAsObservable()
    .pipe(first(), timeoutWith(500, of(null)))
    .toPromise();
  const tenant = await auth.default
    .getTenantAsObservable()
    .pipe(first(), timeoutWith(500, of(null)))
    .toPromise();

  return { user, tenant };
}

function getNowDate() {
  const now = new Date();

  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes()
  );
}

function recordException(error: CanopyError, asyncStacktrace: string) {
  error.showToast = false;
  System.import("error-logging!sofe").then((sentryErrorLogging) => {
    sentryErrorLogging.captureException(error, { asyncStacktrace });
  });
}
